import { wait } from "./promises";
import { deindent } from "./strings";
export const Result = {
    fromThrowing,
    fromThrowingAsync,
    fromPromise: promiseToResult,
    ok(data) {
        return {
            status: "ok",
            data,
        };
    },
    error(error) {
        return {
            status: "error",
            error,
        };
    },
    map: mapResult,
    or: (result, fallback) => {
        return result.status === "ok" ? result.data : fallback;
    },
    orThrow: (result) => {
        if (result.status === "error")
            throw result.error;
        return result.data;
    },
    orThrowAsync: async (result) => {
        return Result.orThrow(await result);
    },
    retry,
};
export const AsyncResult = {
    fromThrowing,
    fromPromise: promiseToResult,
    ok: Result.ok,
    error: Result.error,
    pending,
    map: mapResult,
    or: (result, fallback) => {
        if (result.status === "pending")
            return fallback;
        return Result.or(result, fallback);
    },
    orThrow: (result) => {
        if (result.status === "pending")
            throw new Error("Result still pending");
        return Result.orThrow(result);
    },
    retry,
};
function pending(progress) {
    return {
        status: "pending",
        progress: progress,
    };
}
async function promiseToResult(promise) {
    try {
        const value = await promise;
        return Result.ok(value);
    }
    catch (error) {
        return Result.error(error);
    }
}
function fromThrowing(fn) {
    try {
        return Result.ok(fn());
    }
    catch (error) {
        return Result.error(error);
    }
}
async function fromThrowingAsync(fn) {
    try {
        return Result.ok(await fn());
    }
    catch (error) {
        return Result.error(error);
    }
}
function mapResult(result, fn) {
    if (result.status === "error")
        return {
            status: "error",
            error: result.error,
        };
    if (result.status === "pending")
        return {
            status: "pending",
            ..."progress" in result ? { progress: result.progress } : {},
        };
    return Result.ok(fn(result.data));
}
class RetryError extends AggregateError {
    constructor(errors) {
        const strings = errors.map(e => String(e));
        const isAllSame = strings.length > 1 && strings.every(s => s === strings[0]);
        super(errors, deindent `
      Error after retrying ${errors.length} times.
      
      ${isAllSame ? deindent `
        Attempts 1-${errors.length}:
          ${errors[0]}
      ` : errors.map((e, i) => deindent `
          Attempt ${i + 1}:
            ${e}
        `).join("\n\n")}
      `, { cause: errors[errors.length - 1] });
        this.errors = errors;
        this.name = "RetryError";
    }
    get retries() {
        return this.errors.length;
    }
}
RetryError.prototype.name = "RetryError";
async function retry(fn, retries, { exponentialDelayBase = 2000 }) {
    const errors = [];
    for (let i = 0; i < retries; i++) {
        const res = await fn();
        if (res.status === "ok") {
            return Result.ok(res.data);
        }
        else {
            errors.push(res.error);
            if (i < retries - 1) {
                await wait(Math.random() * exponentialDelayBase * 2 ** i);
            }
        }
    }
    return Result.error(new RetryError(errors));
}
